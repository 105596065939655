/* Hero Section */
.about-hero {
    background-color: rgba(206, 34, 63, 0.082);
    height: 220px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
  }
  
  .about-hero .hero-content h1 {
    font-size: 40px;
    margin-bottom: 20px;
    font-weight: bold;
    background: linear-gradient(
      to right,
      rgba(170, 102, 216, 0.925),
      rgb(211, 74, 177),
      rgb(199, 35, 90)
    );
    -webkit-background-clip: text;
    color: transparent;
    display: inline-block;
  }
  
  .about-hero .hero-content p {
    font-size: 20px;
    background: linear-gradient(
      to right,
      rgba(170, 102, 216, 0.753),
      rgb(211, 74, 177),
      rgb(199, 35, 90)
    );
    -webkit-background-clip: text;
    color: transparent;
    display: inline-block;
  }
  
  .about-page{
    display: flex;
    flex-direction: column;
    min-height: auto;
  }
  /* Team Section */
  .team-section {
    padding: 80px 20px;
    background-color: #f4f4f9;
    
    z-index: 1;
    margin-bottom: auto;
  }
  @media (max-width: 780px) {
    .team-section{
      height:1600px;
      min-height: unset;
    }
  }


  @media (max-width: 750px) {
    .team-section{
      height:2050px;
      min-height: unset;
    }
  }
  @media (max-width: 500px) {
    .team-section{
      height:2250px;
      min-height: unset;
    }
  }

  @media (max-width: 400px) {
    .team-section{
      height:2500px;
      min-height: unset;
    }
  }

  @media (max-width: 370px) {
    .team-section{
      height:2700px;
      min-height: unset;
    }
  }

  
  .container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .team-member {
    display: flex;
    align-items: center;
    margin-bottom: 60px;
  }
  
  .team-member-photo {
    width: 250px;
    border-radius: 50%;
    margin-right: 40px;
  }
  
  .team-member-info {
    flex: 1;
  }
  
  .team-member-info h2 {
    font-size: 28px;
    background: linear-gradient(
      to right,
      rgba(170, 102, 216, 0.925),
      rgb(211, 74, 177),
      rgb(199, 35, 90)
    );
    -webkit-background-clip: text;
    color: transparent;
    display: inline-block;
    margin-bottom: 10px;
  }
  
  .team-member-info h3 {
    font-size: 20px;
    color: #555;
    margin-bottom: 20px;
  }
  
  .team-member-info p {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 20px;
    color: #666;
  }
  
  .team-member-info h4 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .team-member-info ul {
    list-style: none;
    padding: 0;
  }
  
  .team-member-info ul li {
    font-size: 16px;
    color: #333;
    margin-bottom: 10px;
    position: relative;
  }
  
  .team-member-info ul li::before {
    content: '•';
    color: #a200ff;
    font-size: 24px;
    position: absolute;
    left: -20px;
    top: 0;
  }
  
  /* Call to Action Section */
  .cta-section {
    margin-top: auto;
    padding: 80px 20px;
    background-color: #d1b6c98f;
    color: white;
    text-align: center;
  }
  
  .cta-content h2 {
    font-size: 36px;
    margin-bottom: 20px;
    background: linear-gradient(
      to right,
      rgba(156, 49, 228, 0.753),
      rgb(211, 74, 177),
      rgb(199, 35, 90)
    );
    -webkit-background-clip: text;
    color: transparent;
    display: inline-block;
  }
  
  .cta-content p {
    font-size: 18px;
    margin-bottom: 40px;
    background: linear-gradient(
      to right,
      rgba(156, 49, 228, 0.753),
      rgb(211, 74, 177),
      rgb(199, 35, 90)
    );
    -webkit-background-clip: text;
    color: transparent;
    display: inline-block;
  }
  
  .cta-button {
    background-color: white;
    color: #b41a34;
    padding: 15px 30px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    transition: background-color 0.3s ease-in-out;
  }
  
  .cta-button:hover {
    background-color: #ac0e35;
    color: white;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .about-hero .hero-content h1 {
      font-size: 32px;
    }
  
    .about-hero .hero-content p {
      font-size: 16px;
    }
  
    .team-member {
      flex-direction: column;
      text-align: center;
    }
  
    .team-member-photo {
      margin-bottom: 20px;
      margin-right: 0;
    }
  
    .cta-content h2 {
      font-size: 28px;
    }
  
    .cta-content p {
      font-size: 16px;
    }
  }
  