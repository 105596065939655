.form1-hero {
    background-color: rgba(85, 207, 156, 0.219);
    height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(219, 60, 126);
    text-align: center;
  }
  
  .form1-hero .hero-content h1 {
    font-size: 48px;
    margin-bottom: 20px;
  }
  
  .form1-hero .hero-content p {
    font-size: 20px;
  }


  .form2-hero {
    background-color: rgba(197, 152, 5, 0.219);
    height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(219, 60, 60);
    text-align: center;
  }
  
  .form2-hero .hero-content h1 {
    font-size: 48px;
    margin-bottom: 20px;
  }
  
  .form2-hero .hero-content p {
    font-size: 20px;
  }