.faq-hero {
    background-color: rgb(220, 241, 218);
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
  }
  
  .faq-hero .faq-content h1 {
    font-size: 35px;
    margin-bottom: 20px;
    font-weight: bold;
    background: linear-gradient(
      to right,
      rgba(102, 148, 216, 0.753),
      rgb(74, 211, 126),
      rgb(199, 196, 35)
    );
    -webkit-background-clip: text;
    color: transparent;
    display: inline-block;
  }