/* Hero Section */
.contact1-hero {
  background-color:#f0f3f5;
  height: 375px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(65, 61, 61, 0.61);
  text-align: center;
  font-weight: bold;
}

.contact1-hero .contact1-content h2 {
  font-size: 35px;
  margin-bottom: 20px;
  font-weight: bold;
  background: linear-gradient(
    to right,
    rgba(204, 98, 98, 0.863),
    rgb(211, 124, 74),
    rgb(187, 164, 102)
  );
  -webkit-background-clip: text;
  color: transparent;
  display: inline-block;
}

.contact1-hero .hero-content p {
  font-size: 20px;
}

.contact2-hero {
  background-color: #f0f5f0;
  height: 550px;
  display: flex;
  justify-content: center;
  align-items: center;
  color:rgba(65, 61, 61, 0.61);
  text-align: center;
}

.contact2-hero .contact2-content h2 {
  font-size: 35px;
  margin-bottom: 20px;
  font-weight: bold;
  background: linear-gradient(
    to right,
    rgba(204, 98, 98, 0.863),
    rgb(211, 124, 74),
    rgb(187, 164, 102)
  );
  -webkit-background-clip: text;
  color: transparent;
  display: inline-block;
}

.contact2-hero .contact2-content p {
  font-size: 20px;
}


.contact3-hero {
  background-color: rgba(140, 150, 135, 0.116);
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  color:rgba(65, 61, 61, 0.61);
  text-align: center;
}

.contact3-hero .contact3-content h2 {
  font-size: 35px;
  margin-bottom: 20px;
  font-weight: bold;
  background: linear-gradient(
    to right,
    rgba(204, 98, 98, 0.863),
    rgb(211, 124, 74),
    rgb(187, 164, 102)
  );
  -webkit-background-clip: text;
  color: transparent;
  display: inline-block;
}

.contact3-hero .contact3-content p {
  font-size: 20px;
  
}

.contact4-hero {
  background-color: rgba(196, 148, 60, 0.856);
  height: 900px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
}

.contact4-hero .contact4-content h2 {
  font-size: 35px;
  margin-bottom: 20px;
}


/* General Container Styling */
.contact-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.contact-container h2 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

/* Form Styling */
.contact-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-group label {
  margin-bottom: 5px;
  font-weight: bold;
  color: #444;
}

.form-group input, .form-group textarea {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: border-color 0.3s ease;
  color:#55112dfd
}

.form-group input:focus, .form-group textarea:focus {
  border-color: #ffc400;
  outline: none;
}

.form-group textarea {
  resize: vertical;
  min-height: 120px;
}

/* Button Styling */
.submit-btn {
  padding: 12px 20px;
  font-size: 16px;
  color: white;
  background-color: #ca2649d0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-btn:hover {
  background-color: #b3a100;
}

/* Loading Screen Styling */
.loading-screen {
  text-align: center;
  padding: 20px;
}

.loading-screen p {
  margin-top: 10px;
  font-size: 18px;
  color: #0059ff;
}

/* Responsive Design */
@media (max-width: 600px) {
  .contact-container {
    padding: 15px;
  }

  .submit-btn {
    font-size: 14px;
  }
}
