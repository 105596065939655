@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.slider-container {
  width: 80%;
  margin: 50px auto;
  text-align: center;
  max-width:500px;
  margin:0 auto;
}

/* Style the slider track */
.slider {
  -webkit-appearance: none; /* Remove default WebKit appearance */
  appearance: none;
  width: 100%;
  max-width:500px;
  height: 15px;
  background: var(--SliderBackgroundColor);
  border-radius: 10px;
  outline: none;
  transition: background 0.3s;
  border:solid;
  border-width: 2px;
  border-color: rgb(12, 12, 12);
}

/* Webkit browsers (Chrome, Safari) */
.slider::-webkit-slider-thumb {
  width: 28px !important;
  height: 28px !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  background-color: var(--SliderColor) !important;
  border: 2px solid black !important;

}

/* Firefox */
.slider::-moz-range-thumb {
  width: 28px !important;
  height: 28px !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  background-color: var(--SliderColor) !important;
  border: 2px solid black !important;
}

/* Internet Explorer */
.slider::-ms-thumb {
  width: 28px !important;
  height: 28px !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  background-color: var(--SliderColor) !important;
  border: 2px solid black !important;
}

.slider-container {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  max-width: 800px !important; /* Limits width for larger screens */
  margin: 0 auto !important; /* Centers the container */
}
/* .quiz-container {
  height:"2000px" !important;
  background-color: "#B3E5FC" !important;
  padding: 0 2em 0 2em;
} */
@media (max-width: 480px) {
  .slider-container {
      width: 60%; /* Full width on very small screens */
  }
  .container {
    height:200vh !important;
  }
}
.hero-container {
  height: 100vh; 
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  position: relative;
  z-index: 1;
}

/* Overlay Effect */
.hero-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(35, 92, 75, 0.5); /* Dark overlay for better text readability */
  z-index: -1;
}

/* Hero Content */
.hero-content {
  max-width: 800px;
  padding: 20px;
}

.hero-content h1 {
  font-size: 48px;
  margin-bottom: 20px;
  font-weight: bold;
}

.hero-content p {
  font-size: 18px;
  margin-bottom: 40px;
  line-height: 1.5;
}

/* Call to Action Button */
.gs-button {
  padding: 15px 30px;
  font-size: 18px;
  background-color: #5df337c2;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #0056b3;
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero-content h1 {
    font-size: 36px;
  }

  .hero-content p {
    font-size: 16px;
  }

  .cta-button {
    padding: 12px 25px;
    font-size: 16px;
  }
  .container {
    height:200vh;
  }


}

