/* Services Hero Section */
.services-hero {
    background-color: rgba(253, 41, 172, 0.082);
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(219, 60, 60);
    text-align: center;
  }
  
  .services-hero .hero-content h1 {
    font-size: 48px;
    margin-bottom: 20px;
    font-weight: bold;
    background: linear-gradient(
      to right,
      rgba(144, 98, 204, 0.863),
      rgb(211, 74, 193),
      rgb(187, 102, 180)
    );
    -webkit-background-clip: text;
    color: transparent;
    display: inline-block;
  }
  
  .services-hero .hero-content p {
    font-size: 20px;
    background: linear-gradient(
      to right,
      rgba(144, 98, 204, 0.863),
      rgb(211, 74, 193),
      rgb(187, 102, 180)
    );
    -webkit-background-clip: text;
    color: transparent;
    display: inline-block;
  }
  
  /* Services List Section */
  .services-list {
    padding: 80px 20px;
    background-color: #f4f4f9;
  }
  
  .services-list .container {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 40px;
  }
  
  .service-item {
    background-color: white;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s;
  }
  
  .service-item:hover {
    transform: translateY(-10px);
  }
  
  .service-item img {
    width: 80px;
    margin-bottom: 20px;
  }
  
  .service-item h2 {
    font-size: 24px;
    margin-bottom: 15px;
    color: #6f00ff9c;
  }
  
  .service-item p {
    font-size: 16px;
    color: #555;
    line-height: 1.6;
  }
  
  /* Call to Action Section */
  .cta-section {
    padding: 80px 20px;
    background-color: #007BFF;
    color: white;
    text-align: center;
  }
  
  .cta-content h2 {
    font-size: 36px;
    margin-bottom: 20px;
  }
  
  .cta-content p {
    font-size: 18px;
    margin-bottom: 40px;
  }
  
  .cta-button {
    background-color: white;
    color: #007BFF;
    padding: 15px 30px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    transition: background-color 0.3s ease-in-out;
  }
  
  .cta-button:hover {
    background-color: #0056b3;
    color: white;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .services-hero .hero-content h1 {
      font-size: 32px;
    }
  
    .services-hero .hero-content p {
      font-size: 16px;
    }
  
    .cta-content h2 {
      font-size: 28px;
    }
  
    .cta-content p {
      font-size: 16px;
    }
  }
  